<template>
  <div v-loading="loading">
    <div class="flex flex-align-center margin-left-20">
      <div class="blue-line"></div>
      <div class="" style="font-size:28px">
        {{$route.query.info?'线索转换客户':($route.query.Id?'编辑客户':'添加客户')}}
      </div>
    </div>
    <el-form :model="form" ref="form" :rules="rules" label-width="150px" style="margin-bottom:80px">
      <el-card class="margin-top-10">
        <div slot="header">基本信息</div>
        <div class="flex flex-wrap">
          <el-form-item label="公司名称：" prop="CompanyName" class="width-45">
            <el-input v-model="form.CompanyName" style="width:400px" placeholder="公司名称" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="客户来源：" class="width-45">
            <el-select v-model="form.CRMCustomerFromId" style="width:400px" @change="changeSource" placeholder="请选择来源">
              <el-option v-for="(v,i) in sourceList" :key="i" :value="v.Id" :label="v.FromName"></el-option>
            </el-select>
            <el-input v-model="form.CRMCustomerFromRemark" v-if="showSourceRemark" type="textarea" :rows="2" class="margin-top-10"
             style="width:400px" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="客户级别：" class="width-45">
            <el-select v-model="form.CRMCustomerLevel" style="width:400px" placeholder="请选择级别">
              <el-option v-for="(v,i) in levelList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户类型：" class="width-45">
            <el-select v-model="form.CRMCustomerTypeId" style="width:400px" placeholder="请选择类型">
              <el-option v-for="(v,i) in typeList" :key="i" :value="v.Id" :label="v.TypeName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="源线索：" class="width-45">
            <div class="mask">
              <el-input v-model="form.ClueCompanyName" style="width:400px" class="blue-placeholder" disabled></el-input>
              <div class="clickArea" :style="{cursor:Boolean(form.ClueCompanyName)?'pointer':'default'}" @click.stop="viewClue"></div>
            </div>
          </el-form-item>
          <el-form-item label="负责人：" prop="MallEmployeeId" class="width-45" placeholder="请选择负责人">
            <el-select v-model="form.MallEmployeeId" style="width:400px" :disabled="Boolean($route.query.Id)">
              <el-option v-for="(v,i) in chargeList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售区域：" class="width-45">
            <el-cascader
              v-model="form.SellArea"
              placeholder="请选择销售区域"
              style="width:400px"
              @change="changeSellArea"
              :options="PAC"
              :props="{
                value:'id',
                label:'name',
                children:'child'
              }">
            </el-cascader>
          </el-form-item>
          <el-form-item label="联系人姓名：" class="width-45">
            <el-input v-model="form.ContactName" style="width:400px" placeholder="联系人姓名" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="联系人电话：" prop="ContactPhone" class="width-45">
            <el-input v-model="form.ContactPhone" style="width:400px" placeholder="联系人电话"
              onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
              onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
            </el-input>
          </el-form-item>
          <el-form-item label="联系人职务：" class="width-45">
            <el-input v-model="form.ContactPosition" style="width:400px" placeholder="联系人职务" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="固话：" class="width-45">
            <div class="flex flex-align-center gap-10">
              <el-input v-model="form.TelephoneAreaCode" style="width:100px" placeholder="区号" maxlength="20"
                onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
              </el-input>
              <span>-</span>
              <el-input v-model="form.Telephone" style="width:270px" placeholder="" maxlength="20"
                onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
                onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="销售备注：" class="width-45">
            <el-input v-model="form.SellRemark" type="textarea" :rows="4" style="width:400px" maxlength="500"></el-input>
          </el-form-item>
          <el-form-item label="所在地区：" class="width-45">
            <el-cascader
              v-model="form.location"
              placeholder="请选择省-市-区"
              @change="chnangeLocation"
              style="width:400px"
              :options="PACAA"
              :props="{
                value:'id',
                label:'name',
                children:'child'
              }">
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址：" class="width-45">
            <el-input v-model="form.Address" type="textarea" :rows="2" style="width:400px" maxlength="100"></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card class="margin-top-10">
        <div slot="header">工商信息</div>
        <div class="flex flex-wrap">
          <el-form-item label="营业执照名称：" class="width-45">
            <el-input v-model="form.BusinessLicenseName" style="width:400px" placeholder="营业执照名称" maxlength="100"></el-input>
          </el-form-item>
          <el-form-item label="统一社会信用代码：" class="width-45">
            <el-input v-model="form.UnifiedSocialCreditIdentifier" style="width:400px" placeholder="统一社会信用代码" maxlength="18"
              oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/g, '')"></el-input>
          </el-form-item>
          <el-form-item label="工商注册号：" class="width-45">
            <el-input v-model="form.BusinessRegistrationNo" style="width:400px" placeholder="工商注册号" maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="工商注册日期：" class="width-45">
            <el-date-picker
              v-model="form.BusinessRegistrationData"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              style="width:400px"
              type="date"
              placeholder="请选择">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
    <div class="button-card">
      <el-button style="margin-left:-10%" @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" @click="save('form')">保存</el-button>
    </div>
  </div>
</template>

<script>
import area from "@/components/AreaData_classify.json"
import {
  crmCustomerCromlist,
  crmCustomerTypelist,
  crmCustomersave,
  crmCustomerinfo
} from "@/api/sv3.0.0"
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
export default {
  data () {
    return {
      form:{
        CompanyName:'',
        CRMCustomerFromId:'',
        CRMCustomerFromRemark:'',
        CRMCustomerLevel:'',
        CRMCustomerTypeId:'',
        CRMClueId:'',
        ClueCompanyName:'',
        MallEmployeeId:'',
        SellArea:[],
        SellProvince:'',
        SellCity:'',
        ContactName:'',
        ContactPhone:'',
        ContactPosition:'',
        TelephoneAreaCode:'',
        Telephone:'',
        SellRemark:'',
        location:[],
        Province:'',
        City:'',
        Area:'',
        Address:'',
        BusinessLicenseName:'',
        UnifiedSocialCreditIdentifier:'',
        BusinessRegistrationNo:'',
        BusinessRegistrationData:'',
      },
      rules:{
        CompanyName:[
          { required: true, message: '请输入公司名称', trigger: 'blur' },
        ],
        MallEmployeeId:[
          { required: true, message: '请选择负责人', trigger: 'blur' },
        ],
        ContactPhone:[
          { required: true, validator:((rule, value, callback)=>{
            if(!value){
              callback(new Error('请输入联系人手机号'))
            }else if(value.length!=11){
              callback(new Error('请输入正确的手机号'))
            }
            callback()
          }), trigger: 'blur' },
        ]
      },
      sourceList:[],
      showSourceRemark:false,
      levelList:[
        {value:1,label:'重要客户'},
        {value:2,label:'普通客户'},
        {value:3,label:'一般客户'},
        {value:4,label:'其他'},
      ],
      typeList:[],
      chargeList:[],
      PAC:[],
      PACAA:[],
      loading:false,
    }
  },
  mounted () {
    // console.log(this.$route.query.info)
    this.filterArea()
    this.getsource()
    this.getType()
    this.getCharce()
    if(this.$route.query.info){
      let info = JSON.parse(this.$route.query.info)
      for(let key in info){
        this.form[key] = info[key]
      }
       this.form.Id = 0
       this.form.MallEmployeeId = this.form.MallEmployeeId||''
    }
    if(this.$route.query.Id){
      this.getInfo()
    }
  },
  methods: {
    async getInfo(){
      this.loading = true
      try{
        let res = await crmCustomerinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          form.SellArea = this.getAddress(1,form)
          form.location = this.getAddress(2,form)
          this.form = JSON.parse(JSON.stringify({
            ...form,
            CRMCustomerFromId:form.CRMCustomerFromId||'',
            CRMCustomerLevel:form.CRMCustomerLevel||'',
            CRMCustomerTypeId:form.CRMCustomerTypeId||'',
          }))
          this.changeSource(this.form.CRMCustomerFromId)
        }
      }finally{
        this.loading = false
      }
    },
    getAddress(num,form){
      if(num==1){
        let list = ['','']
        for(let item of this.PAC){
          if(item.name==form.SellProvince){
            list[0] = item.id
            for(let item2 of item.child){
              if(item2.name==form.SellCity){
                list[1] = item2.id
                break
              }
            }
          }
        }
        return list
      }else{
        let list = ['','','']
        for(let item of this.PACAA){
          if(item.name==form.Province){
            list[0] = item.id
            for(let item2 of item.child){
              if(item2.name==form.City){
                list[1] = item2.id
                for(let item3 of item2.child){
                  if(item3.name==form.Area){
                    list[2] = item3.id
                    break
                  }
                }
              }
            }
          }
        }
        return list
      }
    },
    chnangeLocation(e){
      if(!e||!e.length) return 
      for(let item of this.PACAA){
        if(item.id==e[0]){
          this.form.Province = item.name
          for(let item2 of item.child){
            if(item2.id==e[1]){
              this.form.City = item2.name
              for(let item3 of item2.child){
                if(item3.id==e[2]){
                  this.form.Area = item3.name
                  break
                }
              }
            }
          }
        }
      }
      console.log(this.form.Province,this.form.City,this.form.Area)
    },
    changeSellArea(e){
      if(!e||!e.length) return 
      for(let item of this.PAC){
        if(item.id==e[0]){
          this.form.SellProvince = item.name
          for(let item2 of item.child){
            if(item2.id==e[1]){
              this.form.SellCity = item2.name
              break
            }
          }
        }
      }
    },
    changeSource(e){
      let item = this.sourceList.find(v=>v.Id==e)
      if(item){
        this.showSourceRemark = item.FromName=='其他'&&item.IsSystemPreset
      }else{
        this.showSourceRemark = false
      }
    },
    async confirmSave(){
      try{
        this.loading = true
        let res = await crmCustomersave(this.form)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.$router.push({
            path:'/Customer/CustomerList'
          })
        }
      }finally{
        this.loading = false
      }
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirmSave()
        } else {
          this.$message.error('客户信息填写有误，请检查')
          this.$nextTick(()=>{
            const element = document.querySelectorAll('.el-form-item__error')[0] 
            // 滚动到错误元素对应位置 
            element.scrollIntoView({ 
              behavior: 'smooth', 
              block: 'center' 
            }) 
          })
          return false;
        }
      });
    },
    filterArea(){
      let PAC = []
      let PACAA = []
      area.map(v=>{
        v.child.map(x=>{
          let newX = JSON.parse(JSON.stringify(x)).child.map(z=>{
            z.child = undefined
            return z
          })
          PACAA.push(x)
          PAC.push({
            ...x,
            child:newX
          })
        })
      })
      this.PAC = PAC
      this.PACAA = PACAA
    },
    viewClue(){
      if(!this.form.CRMClueId) return 
      this.$router.push({
        name: 'clueDataDetails',
        query: {
          id:this.form.CRMClueId
        }
      })
    },
    async getCharce(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.chargeList = res.Result
        }
      }finally{

      }
    },
    async getType(){
      try{
        let res = await crmCustomerTypelist({
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.typeList = res.Result.Results
        }
      }finally{

      }
    },
    async getsource(){
      try{
        let res = await crmCustomerCromlist({
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.sourceList = res.Result.Results
        }
      }finally{

      }
    },
  }
}
</script>

<style lang='less' scoped>
  ::v-deep .blue-placeholder.el-input.is-disabled .el-input__inner{
    color: #409eff;
    
  }
  .button-card{
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    z-index: 999;
  }
  .mask{
    position: relative;
    width: fit-content;
    .clickArea{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .width-45{
    width: 45%;
    margin-left: 10px;
  }
  .blue-line{
    width: 5px;
    height: 24px;
    background: #409EFF;
    margin-right:10px
  }
</style>